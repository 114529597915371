import React from 'react';

const ContactForm = () => {
  return (
    <section className="contact-form-area mb-100" id="get_in_touch">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-heading">
              <div className="line-"></div>
              <h2>Get in touch</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form action="#" method="post">
              <div className="row">
                <div className="col-lg-4">
                  <input type="text" className="form-control" name="name" placeholder="Your Name" />
                </div>
                <div className="col-lg-4">
                  <input type="email" className="form-control" name="email" placeholder="E-mail" />
                </div>
                <div className="col-lg-4">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" />
                </div>
                <div className="col-12">
                  <textarea name="message" className="form-control" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn palatin-btn mt-50">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
