import React, { useEffect, useState } from 'react';

import '../css/footer.css'
const Footer = () => {
  const [logoUrl, setLogoUrl] = useState(null); // State for logo URL
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch('https://airbnb-backend-eight.vercel.app/api/logo/fetch/');
        const data = await response.json();
        setLogoUrl(data.url);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);
  return (
    <footer className="footer-area section-padding-100 bg-img bg-fixed" 
    style={{ 
      backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(img/bg-img/tree.jpeg)', 
      backgroundBlendMode: 'overlay'
    }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="footer-widget-area mt-50">
              <a href="#" className="d-block mb-5"><img src={logoUrl} alt="" /></a>
              <p>Each of our luxurious cottages offer a master bedroom, living area with sofa bed, pantry and private verandah.
              Modern amenities include TV, WiFi, pantry facilities and heated toilet floors.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-2">
            <div className="footer-widget-area mt-50">
              <h6 className="widget-title mb-5">Footer Menu</h6>
              <ul className="footer-menu">
                <li><a href="/home">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/gallery">Gallery</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-widget-area mt-50">
              <h6 className="widget-title mb-5">Connect with Us On Instagram</h6>
                <button className="qr-scan-button" onClick={() => window.open('https://www.instagram.com/dudlymanorbb/')}>
                  <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://www.instagram.com/dudlymanorbb/" alt="QR Code" />
                </button>
              </div>
              </div>
            <div className="col-12 col-md-6 col-lg-3">
            <div className="footer-widget-area mt-50">
              <h6 className="widget-title mb-5">Connect with Us On Facebook</h6>
                <button className="qr-scan-button" onClick={() => window.open('https://www.facebook.com/dudlymanorbnb')}>
                  <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://www.instagram.com/dudlymanorbb/" alt="QR Code" />
                </button>
              </div>
              </div>
            </div>
          </div>
          


          <div className="col-12">
            <div className="copywrite-text mt-30">
              <p>&copy; {new Date().getFullYear()} All rights reserved | This template is made with <i className="fa fa-brain" aria-hidden="true"></i> by <a href="https://kartikarora-portfolio.web.app" target="_blank" rel="noopener noreferrer">Kartik Arora</a></p>
            </div>
          </div>
    </footer>
  );
}

export default Footer;
