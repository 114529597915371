import React from 'react';

const GoogleMap = () => {
  return (
    <div className="map-area mb-100" id="map">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13793.915830023721!2d78.0723886!3d30.1948746!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092fb31d5e2149%3A0x729ca217fa103721!2sStayVista%20at%20Dudley%20Manor!5e0!3m2!1sen!2sin!4v1717000570385!5m2!1sen!2sin"
              allowFullScreen
              style={{ border: 0, width: '100%', height: '450px' }}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
