import React from 'react';

const ContactInformation = () => {
  const contacts = [
    {
      city: 'Dehradun',
      description: 'We welcome you to the city of love , the city of nature, the city which makes you feel like home.',
      address: 'Village Badowala, PO - Nagar Jwalapur; Near Dehra Dun Hills Academy; Mothrowala-Doodhley-Doiwala Road; Doiwala; Dehradun, Uttarakhand-248140',
      phone: '+91 9717078350',
      email: 'dudlymanor@gmail.com',
    }
  ];

  return (
    <section className="contact-information-area">
      <div className="container">
        <div className="row">
          {contacts.map((contact, index) => (
            <div key={index} className="col-12 col-lg-12">
              <div className="single-contact-information mb-100">
                <div className="section-text">
                  <h3>{contact.city}</h3>
                  <p>{contact.description}</p>
                </div>
                <div className="contact-content d-flex">
                  <p>Address</p>
                  <p>{contact.address}</p>
                </div>
                <div className="contact-content d-flex">
                  <p>Phone</p>
                  <p>{contact.phone}</p>
                </div>
                <div className="contact-content d-flex">
                  <p>E-mail</p>
                  <p>{contact.email}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactInformation;
