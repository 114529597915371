import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HeroSlider = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: true,
  };

  return (
    <section className="hero-area">
      <OwlCarousel className="hero-slides owl-carousel" {...options}>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/pool2.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">Gallery</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                    Explore the serene beauty of Dudly Manor Cottages through our gallery. Nestled in the heart of nature, our cottages offer a perfect blend of comfort and tranquility. Discover the picturesque surroundings, luxurious interiors, and the peaceful ambiance that make Dudly Manor a unique retreat. Scroll through our collection of stunning images that capture the essence of our cottages and the breathtaking landscapes that surround us.
                  </p>
                  
                  <a href="#gallery" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Move to Gallery</a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
  
};

export default HeroSlider;
