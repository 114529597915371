import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonials = () => {
    const options = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        dots: true,
      };
  return (
    <section className="testimonial-area section-padding-100 bg-img" style={{ backgroundImage: 'url(img/core-img/pattern.png)' }}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="testimonial-content">
              <div className="section-heading text-center">
                <div className="line-"></div>
                <h2>What Clients Say</h2>
              </div>
              
              <OwlCarousel className="testimonial-slides owl-carousel" {...options}>
                <div className="single-testimonial">
                  <p>Its taken barely a week and we have already forgotten our home, between the trees and the birds,the water and the skies, the cloudy mountains and the glistering fireflies, we are being called into the aura of the nature and its elements.The most exquisite part though is the warm hospiatlity we felt here with marshall and bonzo who came showering us with love (especially for my son and daughter)</p>
                  <h6>Jaya & Sandeep Mehta (Revant & Ananya) <br></br><span>From Vasant Vihar, New Delhi</span></h6>
                  <div class="client-rating" style={{color:"#cb8670"}}>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  </div>

                </div>
                <div className="single-testimonial">
                  <p>This was our first time at dudly manor and it was extremly relaxing, it was our first day in India, and truly enjoyable food was absolutely delicious, The staff was very friendly and the stay served as perfect accomodation, Highly Recommended !!.Thank you .</p>
                  <h6> Laurie Hershenov<br></br><span>From New Jersey, USA</span></h6>
                  <div class="client-rating" style={{color:"#cb8670"}}>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  
                  </div>
                </div>
                <div className="single-testimonial">
                  <p>An absolutely delightful stay all the luxury and comfort of a 5 star hotel combined with love and warmth of a homestay. Would love to revisit at the earliest, forever grateful for Jaya ma'am and Ashok sir gracious hospiatlity</p>
                  <h6> Suneet Kashyap & Aarti Mehra<br></br><span>From Lakes House, Mumbai</span></h6>
                  <div class="client-rating" style={{color:"#cb8670"}}>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  </div>
                </div>
                
                <div className="single-testimonial">
                  <p>The three nights we stayed at the well est. home away from home has given us enough memories to savour for the time to come. the hospiatlity, the easy giving chat with Jaya and Ashok was enlightening. The birding trip to badarkali was also an enriched experience , we saw almost 50 different birds in less than 2 hours in the morning.</p>
                  <h6>Anupam Mukherjee, <br></br><span>From Dehradun, Uttarakhand</span></h6>
                  <div class="client-rating" style={{color:"#cb8670"}}>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  <i class="fas fa-star fa-4x"></i>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
