import React from 'react';

const ServiceIntro = (showModal, toggleModal) => {
  return (
    <section className="services-intro" id="services">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="service-intro-text mb-100">
              <div className="section-heading">
                <div className="line-"></div>
                <h2>Amazing Services</h2>
              </div>
              <p>
                Discover the unparalleled comfort and convenience of our thoughtfully curated amenities and exceptional services designed to make your stay truly unforgettable.
              </p>
              <a onClick={toggleModal} className="btn palatin-btn mt-50" style={{color:"white"}}>Book Now</a>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row mb-100">
              <div className="col-12 col-sm-4">
                <div className="single-cool-fact">
                  <div className="scf-text">
                    <i className="fas fa-cocktail"></i>
                    <p> Beverages</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="single-cool-fact">
                  <div className="scf-text">
                    <i className="fas fa-swimming-pool"></i>
                    <p>Swimming Pool</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                <div className="single-cool-fact">
                  <div className="scf-text">
                    <i className="fas fa-tractor"></i>
                    <p>Farm Visits</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceIntro;
