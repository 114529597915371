import React from 'react';

const Cottages = (showModal, toggleModal) => {
  return (
    <section
      className="pool-area section-padding-100 bg-img bg-fixed"
      style={{ backgroundImage: 'url(img/bg-img/dudly1.png)' }}
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-lg-12">
            <div
              className="pool-content text-center wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="section-heading text-center white">
                <div className="line"></div>
                <h2>Our Cottages</h2>
                <p>
                  We offer two luxurious cottages, each designed to provide the ultimate comfort and relaxation. 
                  Each cottage includes:
                  <ul>
                    <li>A master bedroom with an attached bathroom</li>
                    <li>A living room with a sofa cum bed</li>
                    <li>A pantry along with electronic kettle, fridge, microwave and utensils</li>
                    <li>A private verandah</li>
                  </ul>
                  Additionally, each cottage is equipped with modern amenities including a television, Wi-Fi connectivity, and provisions for tea and coffee. 
                  Each cottage can comfortably accommodate either 3 adults or a family of 4 with children below the age of 13.
                  <br />
                  <br />
                  We also organise Jungle Safaris at The Rajaji Tiger Reserve from mid Novemeber to mid June.
                  <br />
                  <br />
                  Please note that meals are now chargeable at Rs.800/- per meal.
                  Our current tariff is Rs.11,000/- per cottage per night, which includes a complimentary breakfast.
                </p>
              </div>
              
              <a onClick={toggleModal} className="btn palatin-btn mt-50" style={{color:"white"}}>
                Book Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cottages;
