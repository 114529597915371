import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ServiceIntro from '../components/serviceIntro';
import CoreFeatures from '../components/coreFeatures';
import Services from '../components/OurServices';
import HeroSlider from '../components/HeroService';
import BookNowForm from '../components/BookNow';

const Home = ({ showModal, toggleModal }) => {
  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal}/>
      <HeroSlider showModal={showModal} toggleModal={toggleModal}/>
      <BookNowForm showModal={showModal} toggleModal={toggleModal}/>
      <ServiceIntro showModal={showModal} toggleModal={toggleModal}/>
      <CoreFeatures showModal={showModal} toggleModal={toggleModal}/>
      <Services showModal={showModal} toggleModal={toggleModal}/>
      {/* Add more sections as needed */}
      <Footer showModal={showModal} toggleModal={toggleModal}/>
    </div>
  );
}

export default Home;
