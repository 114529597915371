import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import ContactInformation from '../components/ContactInformation';
import ContactForm from '../components/ContactForm';
import GoogleMap from '../components/googleMap';
import HeroSlider from '../components/HeroContact';
import BookNowForm from '../components/BookNow';

const Contact =({ showModal, toggleModal }) => {
  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal}/>
      <HeroSlider showModal={showModal} toggleModal={toggleModal}/>
      <BookNowForm showModal={showModal} toggleModal={toggleModal}/>
      <ContactInformation showModal={showModal} toggleModal={toggleModal}/>
      <ContactForm showModal={showModal} toggleModal={toggleModal}/>
      <GoogleMap showModal={showModal} toggleModal={toggleModal}/>
      {/* Add more sections as needed */}
      <Footer showModal={showModal} toggleModal={toggleModal}/>
    </div>
  );
}

export default Contact;
