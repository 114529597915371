import React, {  useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About'; // Create About component similarly
import Services from './pages/Services'; // Create Services component similarly
import Gallery from './pages/Gallery'; // Create Rooms component similarly
import Contact from './pages/Contact';
import ThankYou from  './pages/ThankYou' // Create Contact component similarly
import './App.css'; 
import 'font-awesome/css/font-awesome.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function App() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home showModal={showModal} toggleModal={toggleModal}/>} />
        <Route path="/about" element={<About showModal={showModal} toggleModal={toggleModal}/>} />
        <Route path="/services" element={<Services showModal={showModal} toggleModal={toggleModal} />} />
        <Route path="/gallery" element={<Gallery showModal={showModal} toggleModal={toggleModal} />} />
        <Route path="/contact" element={<Contact showModal={showModal} toggleModal={toggleModal} />} />
        <Route path="/thank-you" element={<ThankYou showModal={showModal} toggleModal={toggleModal} />} />
      </Routes>
    </Router>
  );
}

export default App;
