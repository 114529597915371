import React from 'react';
import Header from '../components/Header';
import HeroAbout from '../components/HeroAbout';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import Milestones from '../components/milestones';
import Rooms from '../components/Rooms'
import Testimonials from '../components/testimonials';
import BookNowForm from '../components/BookNow';
const Home = ({ showModal, toggleModal }) => {
  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal} />
      <HeroAbout showModal={showModal} toggleModal={toggleModal}/>
      <BookNowForm showModal={showModal} toggleModal={toggleModal}/>
      <AboutUs showModal={showModal} toggleModal={toggleModal}/>
      <Milestones showModal={showModal} toggleModal={toggleModal}/>
      <Rooms showModal={showModal} toggleModal={toggleModal}/>
      <Testimonials showModal={showModal} toggleModal={toggleModal}/>
      <Footer showModal={showModal} toggleModal={toggleModal}/>
    </div>
  );
}

export default Home;
