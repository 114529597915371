import React from 'react';

const Contact = () => {
  return (
    <section className="contact-area d-flex flex-wrap align-items-center">
      <div className="home-map-area">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13793.915830023721!2d78.0723886!3d30.1948746!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092fb31d5e2149%3A0x729ca217fa103721!2sStayVista%20at%20Dudley%20Manor!5e0!3m2!1sen!2sin!4v1717000570385!5m2!1sen!2sin" 
          width="600" 
          height="450" 
          style={{ border: '0' }} 
          allowFullScreen 
          title="Google Map"
        ></iframe>
      </div>
      {/* Contact Info */}
      <div className="contact-info">
        <div className="section-heading wow fadeInUp" data-wow-delay="100ms">
          <div className="line-"></div>
          <h2>Contact Info</h2>
          <p>For any inquiries or assistance, please feel free to contact us using the details below. We look forward to hearing from you!</p>
        </div>
        <h4 className="wow fadeInUp" data-wow-delay="300ms">The Dudly Manor</h4>
        <h5 className="wow fadeInUp" data-wow-delay="300ms">
          Address: Village Badowala, PO - Nagar Jwalapur; Near Dehra Dun Hills Academy; Mothrowala-Doodhley-Doiwala Road; Doiwala; Dehradun, Uttarakhand-248140
        </h5>
        <h5 className="wow fadeInUp" data-wow-delay="400ms">Phone: +91-9717078350, +91-9897119305</h5>
        <h5 className="wow fadeInUp" data-wow-delay="500ms">Email: dudlymanor@gmail.com</h5>
        {/* Social Info */}
        <div className="social-info mt-50 wow fadeInUp" data-wow-delay="600ms">
          <a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a>
          <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
          <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
          <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
          <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
          <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
