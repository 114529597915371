import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HeroSlider = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: true,
  };

  return (
    <section className="hero-area">
      <OwlCarousel className="hero-slides owl-carousel" {...options}>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/sunbed.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">The Vacation Heaven</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                  Dudly Manor is a vacation haven offering an idyllic blend of serene natural beauty, sustainable living, and immersive cultural experiences.
                  Each cottage can comfortably accommodate either 3 adults or a family of 4 with children below the age of 13.
                  Our current tariff is Rs.11,000/- per cottage per night, which includes a complimentary breakfast.
                  </p>
                  <a href="#aboutus" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/cottage.jpeg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">A place to remember</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                    Dudly Manor is a place to remember for its unique blend of breathtaking landscapes, authentic farm-to-table experiences, and deep cultural immersion in the heart of nature.
                  </p>
                  <a href="#aboutus" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/entrance.jpeg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">Enjoy your life</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                  Enjoy life at Dudly Manor by embracing the tranquility of organic farming, participating in traditional cooking classes, exploring scenic trails, engaging in bird watching, and relaxing with activities like swimming and sunbathing amidst picturesque Himalayan views.
                  </p>
                  <a href="#aboutus" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default HeroSlider;
