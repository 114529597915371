import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const WhyChooseUs = ({ showModal,toggleModal}) => {
  return (
    <section className="about-us-area" id='aboutus' style={{
      paddingBottom:"100px" 
    }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <div className="section-heading text-center">
              <h2>Why Choose Us</h2>
              <p>Experience the charm of Dudly Manor for an unforgettable stay amidst nature and tradition.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="about-content">
              <p><strong>Sustainable Living:</strong> Enjoy an eco-friendly retreat with organic farms, solar water heating, and rainwater harvesting</p>
              <p><strong>Farm Visits:</strong> Engage in traditional cooking classes, farm work, bird watching, and village tours for a genuine agro-tourism experience.</p>
              <p><strong>Personalized Hospitality:</strong> Enjoy warm, personalized service with activities led by local experts and the host.</p>
              <p><strong>Convenient Excursions:</strong> Easily explore nearby attractions like Rishikesh, Haridwar, and Mussoorie with curated day trips arranged by the hosts.</p>
              <a onClick={toggleModal} style={{color: "white"}} className="btn palatin-btn mt-50">
                Book Now
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="about-thumbnail">
              <img src="img/bg-img/hall.jpg" alt="About Us"/>
            </div>
          </div>
        </div>
      </div>

      {/* Inline styles to include media query */}
      <style jsx>{`
        @media (max-width: 768px) {
          .learn-more-btn {
            margin-bottom: 100px;
          }
        }
      `}</style>
    </section>
  );
};

export default WhyChooseUs;
