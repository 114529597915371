import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Utility function to format dates
const formatDate = (date) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
}

// Function to generate date options
const generateDateOptions = (start, end) => {
    const dates = [];
    const currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
        dates.push(formatDate(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

const BookNowForm = () => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 360);

    const checkInOptions = generateDateOptions(today, nextWeek);
    const checkOutOptions = generateDateOptions(today, nextWeek);
    
    const adultsOptions = ['01','02', '03', '04', '05', '06'];
    const childrenOptions = ['01', '02', '03', '04', '05'];

    const [checkIn, setCheckIn] = useState(checkInOptions[0]);
    const [checkOut, setCheckOut] = useState(checkOutOptions[1]);
    const [adults, setAdults] = useState(adultsOptions[0]);
    const [children, setChildren] = useState(childrenOptions[0]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleConfirmBooking = async () => {
        const checkInDate = new Date(checkIn).toISOString();
        const checkOutDate = new Date(checkOut).toISOString();

        const bookingData = {
            checkInDate,
            checkOutDate,
            adults: parseInt(adults),
            children: parseInt(children),
            name,
            contactNumber,
            email
        };

        try {
            const response = await fetch('https://airbnb-backend-eight.vercel.app/api/bookings', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bookingData)
            });

            if (response.status === 200 || response.status === 201) {
                window.location.href="/thank-you"
                setShowModal(false);
            }
            else if( response.status == 401) {
                alert('Slot is already booked, Please choose a different date');
            }
             else {
                alert('Failed to confirm booking.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while confirming your booking.');
        }
    };

    return (
        <div className="book-now-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-12">
                        <div className="book-now-form">
                            <form>
                                {/* Form Group */}
                                <div className="form-group">
                                    <label htmlFor="select1">Check In</label>
                                    <select className="form-control" id="select1" value={checkIn} onChange={(e) => setCheckIn(e.target.value)}>
                                        {checkInOptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Form Group */}
                                <div className="form-group">
                                    <label htmlFor="select2">Check Out</label>
                                    <select className="form-control" id="select2" value={checkOut} onChange={(e) => setCheckOut(e.target.value)}>
                                        {checkOutOptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Form Group */}
                                <div className="form-group">
                                    <label htmlFor="select3">Adults</label>
                                    <select className="form-control" id="select3" value={adults} onChange={(e) => setAdults(e.target.value)}>
                                        {adultsOptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Form Group */}
                                <div className="form-group">
                                    <label htmlFor="select4">Children</label>
                                    <select className="form-control" id="select4" value={children} onChange={(e) => setChildren(e.target.value)}>
                                        {childrenOptions.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Button */}
                                <button onClick={handleSubmit} className="btn palatin-btn">Book Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number</label>
                            <input type="tel" className="form-control" id="mobile" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder="Enter your mobile number" required/>
                        </div>
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="select1">Check In</label>
                                <select className="form-control" id="select1" value={checkIn} onChange={(e) => setCheckIn(e.target.value)}>
                                    {checkInOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Form Group */}
                            <div className="form-group col-6">
                                <label htmlFor="select2">Check Out</label>
                                <select className="form-control" id="select2" value={checkOut} onChange={(e) => setCheckOut(e.target.value)}>
                                    {checkOutOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* Form Group */}
                        <div className='row'>
                            <div className="form-group col-6">
                                <label htmlFor="select3">Adults</label>
                                <select className="form-control" id="select3" value={adults} onChange={(e) => setAdults(e.target.value)}>
                                    {adultsOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Form Group */}
                            <div className="form-group col-6">
                                <label htmlFor="select4">Children</label>
                                <select className="form-control" id="select4" value={children} onChange={(e) => setChildren(e.target.value)}>
                                    {childrenOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn palatin-btn" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" className="btn palatin-btn" onClick={handleConfirmBooking}>Send Booking Request</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BookNowForm;
