import React from 'react';
import Header from '../components/Header';
import Hero from '../components/HeroGallery';
import Footer from '../components/Footer';
import Gallery from '../components/Photos';

const Home = ({ showModal, toggleModal }) => {
  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal}/>
      <Hero showModal={showModal} toggleModal={toggleModal}/>
      <Gallery  showModal={showModal} toggleModal={toggleModal}/>
      {/* Add more sections as needed */}
      <Footer showModal={showModal} toggleModal={toggleModal} />
    </div>
  );
}

export default Home;
