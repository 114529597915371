import React from 'react';

const Hotels = () => {
  return (
    <section className="our-hotels-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-heading text-center">
              <div className="line-"></div>
              <h2>Our Rooms</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-hotel-info mb-100">
              <div className="hotel-info-text">
                <h6><span className="fa fa-check"></span> 4G hotspot for reliable internet</h6>
                <h6><span className="fa fa-check"></span> Bonfire available during winter</h6>
                <h6><span className="fa fa-check"></span> Access to books and games</h6>
              </div>
              <div className="hotel-img">
                <img src="img/bg-img/room.jpg" alt="Hotel" />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-hotel-info mb-100">
              <div className="hotel-info-text">
                <h6><span className="fa fa-check"></span> Provided toiletries and clean linens</h6>
                <h6><span className="fa fa-check"></span> Complimentary Beverages</h6>
                <h6><span className="fa fa-check"></span> Mini fridge with Cold water</h6>
              </div>
              <div className="hotel-img">
                <img src="img/bg-img/room2.jpg" alt="Hotel" />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-hotel-info mb-100">
              <div className="hotel-info-text">
                <h6><span className="fa fa-check"></span> Hot/cold air conditioner</h6>
                <h6><span className="fa fa-check"></span> Electric kettle for hot beverages</h6>
                <h6><span className="fa fa-check"></span> Microwave for heating food</h6>
              </div>
              <div className="hotel-img">
                <img src="img/bg-img/room3.jpg" alt="Hotel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hotels;
